/**
 * Layout
 * ------
 * Defines the top level shared layouts used by the Century 21 website.
 * Do not include section specific components in this file, define shared layouts (eg. 2 column) ONLY.
 * @charset "utf-8";
 */

@import "newPortal.less";

/**
 * Global Element Overrides
 */
html, body {
   height: 100%;
}
* {
   * {
    margin:0;
   }
}
body {
   line-height: 1.7em;
   font-family: @body-font;
   font-size:12px;
   color: @redesign-black;
   font-weight:normal;
   background-size: cover;
   background-position: top center;
   background-color: @white;
   background-attachment: fixed;
   /*background-image: url('/images/backgrounds/blurry-background.jpg');*/
/*   background-color: #fff; */
}
body{
   &.bg-img, &.adp, &.odp, &.fah, &.fahMap, &.fahMapStateDetail, &.fahSchool, &.calculator, &.findAndContactAgentPage, &.contactListAPropertyPage, &.contactGeneralPage,
   &.contactFranchisePage, &.contactMediaPage, &.contactLanding, &.lrp, &.srp, &.glossary, &.sdp, &.localArea, &.myc21-overview {
      background-image: url('/images/backgrounds/blurry-background.jpg');
   }
}
body.content-page, body.error {
   background-repeat: no-repeat;
   // background-color: #000;
   #PageFrame {
      padding-top: 265px;
      width: 100%;
      @media (max-width: @screen-phone-max) {
         padding-top: 0;
         min-width: inherit;
      }
   }
}
body.error {
   .search-toggle {
      display: none !important;
   }
   //#bodyMain {
   //   min-height: 160vh;
   //}
   //@media (max-width: @screen-phone-max) {
   //   #bodyMain {
   //      min-height: 190vh;
   //   }
   //}
}
/*** Needed here for Critical CSS ***/
body.home{
   .site-header .header-main-bar {
      @media (min-width: @screen-tablet-min){
         //background-color: transparent;

         #dropdownSearchBox{
            width: 0;
         }
      }
   }
}

//
// Typography
// --------------------------------------------------
/*.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
   margin-bottom: .5rem;
   line-height: 1.2;
}
h1, .h1, h2, .h2 {
   font-family: @subhead-condensed-font;
   line-height: 1em;
}
h1, .h1 {
   font-size: 34px;
   font-weight: bold;
   margin-bottom: .75rem;
}
h2, .h2 {
   font-size: 20px;
   font-weight: bold;
   //letter-spacing: 2.56px;
}
h3, .h3 {
   font-size: 16px;
   font-family: @headline-font;
   color: @color-ada-gold;
   line-height: 1.2rem;
}
h4, .h4 {
   font-size: 10px;
   font-family: @subhead-extended-font;
}*/
p {
   margin-top: 0;
   margin-bottom: 1rem;
}
a {
   color: @color-c21-darkest-gold; // @blue;
}

.dark-gold {
   color: @color-c21darkgold;
}
.gold {
   color: @color-c21gold;
}
//
// Buttons
// --------------------------------------------------


body button {
   font-family: @subhead-font;
   &:focus {
      outline:0;
   }
}
.btn{
   font-family: @subhead-font;
   font-weight: bold;
   letter-spacing: 1.6px;
   text-transform: uppercase;
   font-size: 10px;
   padding: .25em .75em;
   text-decoration: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   margin: 0.5em .5em .5em 0;
   display: inline-block;
   text-align: center;
   vertical-align: middle;
   min-height: 27px;
   cursor: pointer;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   &-primary {
      color: @redesign-black;
      background-color: @color-c21gold;
      border-color: @color-c21gold;
      &:hover, &:focus {
         background-color: darken(@color-c21gold, 10%);
         border-color: darken(@color-c21gold, 10%);
      }
   }
   &-outline-primary {
      color: @color-ada-gold;
      border-color: @color-ada-gold;
      &:hover, &:focus {
         color: @white;
         background-color: @color-ada-gold;
      }
   }
   &-outline-secondary {
      color: @redesign-black;
      border-color: @redesign-black;
      &:hover, &:focus {
         color: @white;
         background-color: @redesign-black;
      }
   }
   .svg-inline {
      vertical-align: middle;
      padding-left: 4px;
      svg {
         width: 10px;
         height: 10px;
      }
   }
}

//Close Button
.close {
   position: absolute;
   right: 15px;
   width: 35px;
   height: 35px;
   background-color: transparent;
   .pngIconFallback(0, 0, '/images/portal/button-close');
   border: 0;
   -webkit-appearance: none;
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1;
   color: #000;
   text-shadow: 0 1px 0 #fff;
   cursor: pointer;
}



.error-body {
   background: #fff;
   padding: 70px 24px 24px 24px
}
/*
We were overriding this too many places, so it's now removed.
Add it back only to your specific areas where necessary.
a:hover {
   color: #eeb111;
}
*/
.Smaller {
   font-size: 0.85em;
   font-weight: normal;
   &:hover, &:focus {
      text-decoration: underline;
   }
}
.Larger {
   font-size: 1.15em;
}

label input {
   line-height: normal;
}

/**
 * Page Level Element Containers
 */
#PageFrame {
   position: relative;
   margin: auto;            /* center our content in the viewport */
   height:auto !important;  /* real browsers */
   //height:100%;             /* IE6: treaded as min-height TODO Is this required anymore?*/
   //min-height:100%;         /* real browsers TODO Is this required anymore? */
   width: 984px;
   z-index: 0;
   top: 0;
   padding-bottom: 80px;
   @media(min-width: @screen-sm) {
      top: 80px;
   }
   @media (max-width: @screen-tablet-max) {
      width: 769px;
   }
}
#PageBody {
   position: relative;
   padding-bottom: 46px;    /* Keep content from getting hidden by footer */

   height:auto !important;  /* real browsers */
   height:50%;              /* IE6: treaded as min-height*/
   min-height:50%;          /* real browsers */
}
#PageHeader {
   position:relative;
   height: 242px;
   position: relative;
}

#PageBreadcrumbs {
   margin-top: 30px;
   height: 25px;
   padding: 5px 21px 0 21px;
   line-height: 20px;
   font-size: 11px;
   font-weight: normal;
   color: #fff;
   background: @color-c21grey; // #414042;
   @media(max-width: @screen-tablet-max) {
      margin-top: 0;
   }
   a {
      color: #ddd;
   }
}

body.contactListAPropertyPage {
   #PageBreadcrumbs {
      a {
         color: #444;
         &:hover {
            color: @color-ada-gold;
         }
      }
   }
}

#PageFrame {
   #PageBreadcrumbs {
      @media (max-width: @screen-tablet-max) {
         background: none;
         background-color: #38383a;
      }
      @media (max-width: @screen-phone-max) {
         margin-top: 0;
      }
   }
}


#PageBreadcrumbs a:hover {
   color: #fff;
   text-decoration: underline;
}

#PageBreadcrumbs .C2msBlock, #PageBreadcrumbs .C2msSection {
   display: inline-block;
}

#PageFooter {
   position: relative;
   /*height: 485px;*/
   width: 100%;             /* Auto size width to that of PageFrame, we must specify since we are absolutely positioned */
   background: url( /images/layout/pagefooterbg.png ) 0 0 repeat-y;
}
#PageFooter.forcebottom {
   height: 390px;
   position: absolute;      /* Take out of flow, and pin to bottom of page */
   bottom: -336px;          /* Show only the top of the footer on the page, hide the rest */
}

/**
 * Header Level Elements
 */
#HeaderActions {
   height: 23px;
   position: relative;
   background: url(/images/layout/texturesheet.png) 0px 0px no-repeat;
   font-size: 10px;
   color: #a1a1a1;
   padding: 0 28px;
   line-height: 22px;
   overflow: hidden;
   text-align: right;
}
#HeaderNavigation {
   position: relative;
   height: 69px;
   background: url(/images/layout/texturesheet.png) 0px -24px no-repeat;
   padding: 0 10px;
   overflow: hidden;
}
#HeaderNavigation #dbaLogo {
   width: 115px;
   position:  absolute;
   top: 5px;
}
#HeaderSearch {
   position: relative;
   height: 150px;
}
#HeaderLoggedIn {
   display: none;
}

#BrowserWarning{
   z-index: 1;
   height: 200px;
   position: relative;
   margin-bottom:25px;
   color:#000;
}

#BrowserFrame{
   z-index: 2;
   display: block;
   left: 159px;
   padding: 0px;
   position: relative;
   width: 666px;
}

#BrowserContent{
   height: 200px;
   width: 666px;
}

/**
 * Footer Level Elements
 */
#FooterNavigation {
   padding: 0 40px 0px 31px;
   background: url(/images/layout/texturesheet.png) 0px -97px no-repeat;
   height: 46px;
   line-height: 68px;
   text-transform: uppercase;
   font-weight: bold;
}
#FooterSitemap, #FooterTopMarkets {
   padding: 0 23px 0px 23px;
   margin-top: 10px;
   zoom: 1;
}
#FooterTopMarkets {
   text-align: center;
}

#FooterSitemap:after {
   content: ".";
   display: block;
   height: 0;
   clear: both;
   visibility: hidden;
}
#FooterClear {
   clear: both;
}
#FooterGlassRule {
   position: relative;
   margin-top: -2px;
   height: 2px;
   background: url(/images/layout/texturesheet.png) 0px -280px no-repeat;
   overflow: hidden;
}
#FooterNavigation a {
   padding: 0px 10px 0px 9px;
   font-size:12px;
   line-height:14px;
   color:#898989;
   text-decoration: none;
   border-right: 2px solid #AFAFAF;
}
#FooterNavigation a:hover { color:#555; }
#FooterNavigation .last { border-right:none !important; }
#FooterNavigation a.IconSocialMedia {
   opacity: 0.75;
   float: left;
   display: block;
   border-right: 0;
   padding: 0;
   margin-right: 3px;
}
#IconSocialMediaContainer {
   width: 156px;
   padding-top: 19px;
   padding-right: 21px;

   /*float: right;*/
   /* Instead of floating this, use absolute positioning to
    * correct for bug in WebKit that does not reflow floated
    * items after scaling up from a previously smaller container.
    */
   position: absolute;
   top: 0;
   right: 164px;
}
#FooterNavigation a.IconSocialMedia:hover {
   opacity: 1;
}
#FooterLegal {
   text-align: center;
   color:#999999;
   text-decoration:none;
   font-size: 10px;
   padding: 0 23px 20px 23px;
   margin-top: 16px;
   line-height: 13px;
}
.FooterBar {
   border-top: 1px solid #c2c2c2;
   width: 100%;
   margin-bottom: 13px;
   line-height: 1px;
}
.FooterBarInside {
   border-top: 1px solid #ebebeb;
   width: 100%;
   line-height: 1px;
}
#FooterNavigation #websiteFeedbackLink {
   line-height: 68px;
   padding: 0;
   float: right;
}

.limbaughResponse {
   color: #000000;
}

.limbaughResponse a {
   font-size: 12px;
}

/**
 * Sitemap Level Elements
 */
#FooterSitemap .SitemapLane {
   float: left;
   width: 230px;
   line-height: 13px;
   border-right: 1px solid #ebebeb;
   margin-top: 5px;
}
#FooterSitemap .SitemapLaneInside {
   height: 140px;
   padding-top: 10px;
   padding-left: 23px;
   border-right: 1px solid #c2c2c2;
}
#FooterSitemap .last .SitemapLaneInside {
   border-right: 0;
}
#FooterSitemap .SitemapLane.last {
   border-right: 0;
}
#FooterSitemap .SitemapLane a {
   color:#898989;
   text-decoration:none;
   font-size: 12px;
   line-height: 14px;
}
#FooterTopMarkets .FooterRow a, #FooterTopMarkets .FooterRow {
   color:#898989;
   text-decoration:none;
   font-size: 9px;
   line-height: 14px;
}
#FooterSitemap .SitemapLane a:hover, #FooterTopMarkets .FooterRow a:hover {
   color: #38383a; // #0B5095;
}

/**
 * Body Level Elements
 */
#BodyClear {
   clear: both;
}
.LaneTitle {
   color: #fff;
   font-weight: bold;
   font-size: 18px;
   line-height: 38px;
   height: 40px;
   padding: 0 10px;
   overflow: hidden;
   // Breaks agent results page: max-width: 100% !important;
   background: @color-c21grey; // #414042; /* url(/images/layout/texturesheet.png) -2px -170px no-repeat; */
}
.LaneTitle h1 {
   color: #fff;
   font-size: 18px;
   //padding-top: 10px;
   overflow: hidden;
   float: left;
}
.LaneTitle a {
   color:@color-c21-gold; // #fec321;
}
.LaneTitle b {
   color:@color-c21-gold; // #fec321;
}
.LaneTitle a.secondaryLink {
   float: right;
   font-size: 11px;
   color: #fff;
   line-height: 40px;
   &:hover, &:focus {
      text-decoration: underline;
   }
}
.LaneTitle span.secondaryLink {
   float: right;
   font-size: 11px;
   color: #fff;
   line-height: 40px;
}
.LaneSubTitle {
   color: @color-c21grey; // #003663;
   font-size: 10px;
   font-weight: bold;
   height: 23px;
   line-height: 23px;
   overflow: hidden;
   /*background: url( /images/layout/texturesheet.png ) -1569px -139px no-repeat;*/
}
.LaneSubTitle.viewType {
   font-size: 11px;
}
.LaneSubTitle a {
   color: @color-c21grey; // #003663;
}
/*eg View Options*/
.LaneOptions {
   float: right;
   font-size: 12px;
   font-weight: bold;
}
.LaneOptions a {
   color: #fff;
}
.LaneOptions a.Active {
   color: @color-c21-gold; // #fec321;
}
.CenterLaneCard.agentCard .CardDetails .primaryDetails.agentCardDetails {
   color: @obsessed-grey;
}

/**
 * Action Level Elements
 */
#HeaderActions a {
   color: #a1a1a1;
}
#HeaderActions a:hover {
   color: #fff;
}
#HeaderActions .actionLabel {
   color: @color-c21-gold; // #fec321;
}
#ActionCall {
   float: left;
   background: url(/images/icons/callicon.png) 0px 4px no-repeat;
   padding-left: 14px;
}
a#myc21UsernameLabel, #ActionCall a {
   color: white;
}
#ActionCall a {
}
#HeaderActions #siteLinks {
   float: left;
   margin-left: 20px;
   font-size: 11px;
}
/**
 * Navigation Level Elements
 */
#NavigationHome {
   display: block;
   margin-left: 14px;
   margin-top: 11px;
   height: 44px;
   width: 99px;
   overflow: hidden;
   line-height: 100px;
   background: url(/images/layout/texturesheet.png) -885px -445px no-repeat;
}
#NavigationHome.dbaHeader {
   background: none;
}

.presidentAwardBanner {
   float: right;
   width: 110px;
   text-align: right;
   text-transform: uppercase;
   color: #ec5d3f;
   font-weight: 600;
   padding-top: 13px;
}

#NavigationList {
   position: absolute;
   bottom: 6px;
   right: 30px;
   list-style:none;
   margin: 0;
}
#NavigationList li {
   float: left;
}
#NavigationList li a {
   display: block;
   overflow: hidden;
   line-height: 40px;
   padding: 5px 0 0 3px
}
.NavigationSeparator {
   display: block;
   background: url(/images/layout/texturesheet.png) 0px -809px no-repeat;
   width: 3px;
   height: 15px;
   margin: 0 10px;
}
#NavigationHomeLink{background:url(/images/layout/texturesheet.png) -307px -809px no-repeat; width: 118px; height: 13px;margin-right: 360px; }
#NavigationAdvice {background:url(/images/layout/texturesheet.png) -254px -809px no-repeat; width: 47px; height: 13px; }
#NavigationFindHome{background:url(/images/layout/texturesheet.png) -430px -809px no-repeat; width: 83px; height: 13px; }

#NavigationBuying {
   background: url(/images/layout/texturesheet.png) -10px -809px no-repeat;
   width: 113px;
   height: 15px;
}
#NavigationCommunity {
   background: url(/images/layout/texturesheet.png) -128px -809px no-repeat;
   width: 78px;
   height: 15px;
}
#NavigationTools {
   background: url(/images/layout/texturesheet.png) -210px -809px no-repeat;
   width: 40px;
   height: 15px;
}
#NavigationJoinC21 {
   background:url(/images/layout/texturesheet.png) -518px -809px no-repeat;
   width: 56px;
   height: 13px;
}
.SendButton {
   background:url(/images/buttons/sendListingsSection.jpg) 0px 0px no-repeat;
   width: 47px;
   height: 22px;
   float:right;
}
.SendButton:hover {
   background-position: 0px -27px !important;
}

/**
 * Pagination Area
 */
.TopPagination {
   position: relative;
   z-index: 2;     /* Raise the pagination higher, so the drop shadow covers the item below it */
   height: 33px;
   margin-bottom: -3px;
   color: #707070;
   font-weight: bold;
   font-size: 10px;
   line-height: 30px;
   background: url(/images/layout/texturesheet.png) -2px -212px no-repeat;
   overflow: hidden;
}
.BottomPagination {
   position: relative;
   bottom: 0;
   left: 2px;
   height: 31px;
   width: 980px;
   color: #707070;
   font-weight: bold;
   font-size: 11px;
   line-height: 30px;
   background: url(/images/layout/texturesheet.png) -2px -247px no-repeat;
   overflow: hidden;
}
#sortLinkContainer {
   font-size: 11px;

   a {
      color: #000 !important;

      &:hover, &:focus {
         color: #5F5544 !important;
         text-decoration: underline;
      }
   }
}
.PaginationSort {
   position: relative;
   float: left;
   margin-left: 10px;
}
.PaginationSort .Label {
   font-size: 11px;
}
.PaginationSort .Value {
/*
   position: absolute;
   background: url(/images/layout/texturesheet.png) -870px -353px no-repeat;
   width: 97px;
   height: 22px;
   padding-left: 7px;
   top: 3px;
   left: 55px;
   line-height: 22px;
   color: #000;
   cursor: pointer;
*/
}
.PaginationLimit {
   position: relative;
   margin-left: 10px;
}
.TopPagination .PaginationLimit {
   float: left;
}
.TopPagination .PaginationLimit .ui-state-active,
.TopPagination .PaginationSort .ui-state-active,
.BottomPagination .PaginationLimit .ui-state-active {
   color: @obsessed-grey;
}
.BottomPagination .PaginationLimit {
   position: absolute;
   right: 19px;
   top: 2px;
}
.PaginationLimit .Label {
   font-size: 11px;
}
.PaginationLimit .Value {
   position: absolute;
   background: url(/images/layout/texturesheet.png) -870px -377px no-repeat;
   width: 44px;
   height: 22px;
   padding-left: 7px;
   top: 3px;
   left: 60px;
   line-height: 22px;
   color: #000;
   cursor: pointer;
}
.PagerMini {
   position: relative;
   float: right;
   padding-right: 59px;
   margin-right: 19px;
   font-size: 11px;
}
.PagerMini .PagerCurrentPage {
   color: #000;
}
.PagerMini .PagerTotalPages {}
.PagerMini .PagePrevious {
   display: block;
   width: 22px;
   height: 22px;
   position: absolute;
   top: 3px;
   right: 25px;
   background: url(/images/layout/texturesheet.png) -918px -377px no-repeat;
}
.PagerMini .PagePrevious.disabled, .PagerMini .PagePrevious.disabled:hover {
   background: url(/images/layout/texturesheet.png) -909px -401px no-repeat;
}
.PagerMini .PageNext {
   display: block;
   width: 22px;
   height: 22px;
   position: absolute;
   top: 3px;
   right: 0;
   background: url(/images/layout/texturesheet.png) -942px -377px no-repeat;
}
.PagerMini .PageNext.disabled, .PagerMini .PageNext.disabled:hover {
   background: url(/images/layout/texturesheet.png) -933px -401px no-repeat;
}
.PagerMini .PageNext:hover {
   background-position: -942px -353px;
}
.PagerMini .PagePrevious:hover {
   background-position: -918px -353px;
}
.PagerFull {
   position: relative;
   color: #707070;
   margin-left: 17px;
   margin-top: 1px;
}
.PagerFull .PagePrevious {
   display: inline-block;
   height: 20px;
   top: 3px;
   right: 25px;
   background: url(/images/layout/texturesheet.png) -2px -284px no-repeat;
   color: #707070;
   border: 1px solid #b7b7b7;
   padding: 0 6px;
   line-height: 19px;
   margin-right: 5px;
}
.PagerFull .PageNext {
   display: inline-block;
   height: 20px;
   top: 3px;
   right: 0;
   background: url(/images/layout/texturesheet.png) -2px -284px no-repeat;
   color: #707070;
   border: 1px solid #b7b7b7;
   padding: 0 6px;
   line-height: 19px;
   margin-left: 5px;
}
.PagerFull .Page {
   padding: 0 2px;
   color: #707070;
}
.PagerFull .Page:hover {
   text-decoration: underline;
}
.PagerFull .Page.PagerCurrentPage,
.PagerFull .PagerCurrentPage {
   color: #000;
   text-decoration: underline;
}


/* ********************************************************************************************************* */
/* ******************************** START SHARED LOGO CLASS DEFINITIONS ************************************ */
/* ********************************************************************************************************* */

.EHO {
   display:inline-block;
   background:url(/images/layout/texturesheet.png) -965px -353px no-repeat;
   width: 19px;
   height: 16px;
}
#TermValueSelect {
   background:url(/images/layout/texturesheet.png) -870px -401px no-repeat;
   width: 85px;
   height: 22px;
}
.Required, .required {
   color: #B60000;
}


/* ********************************************************************************************************* */
/* ****************************** START SIDE NAVIGATION CLASS DEFINITIONS ********************************** */
/* ********************************************************************************************************* */

.SideNavigationTitle {
   color: @gold;
   font-weight: bold;
   font-size: 14px;
   line-height: 38px;
   height: 40px;
   padding: 0 10px;
   overflow: hidden;
   // background: url(/images/layout/texturesheet.png) -668px -521px no-repeat;
   background: @color-c21grey;
   padding-left: 19px;
   padding-right: 19px;
   margin-bottom: 15px;
}
#BodyLeftLane .SideNavigation ul {
   margin: 0 0 0 19px;
   padding: 0;
   padding-right: 19px;
   padding-bottom: 15px;
   list-style: none;
}
#BodyLeftLane .SideNavigation ul li a {
   font-weight: normal;
   font-size: 12px;
   display: block;
   padding: 5px 0;
   color: #363636;
   text-shadow:#ffffff 1px 1px 2px;
}
#BodyLeftLane .SideNavigation ul li a.current {
   color: @color-ada-gold;
}
#BodyLeftLane .SideNavigation ul li a:hover {
   color: darken(@color-ada-gold, 10%);
}


/* ********************************************************************************************************* */
/* ****************************** START INDIVIDUAL LAYOUT TYPE DEFINITIONS ********************************* */
/* ********************************************************************************************************* */

/**
 * Use to define the standard padding all elements should default to for textual content within a lane
 */
.LaneText {
   padding: 10px;
   &.schoolFootnoteTxt {
      width: 100% !important;
   }
}

.Standard1Lane .LaneText{
   padding: 31px 21px;
}

.Standard1Lane .LaneTitle{
   padding-left: 21px;
}

/**
 * Standard 3 Column Layout
 */
.Standard3Lane {
   background: url( /images/layout/pagebodybg_standard3lane.png ) 0 0 repeat-y;
}
body.res {
   .calculator3Lane {
      @media(max-width: @screen-phone-max) {
         #BodyLeftLane, #BodyCenterLane, #BodyRightLane {
            width: 100%;
            float: none;
         }
         #BodyLeftLane {
            min-height: inherit;
            .SideNavigationTitle {
               background: none;
               background-color: @color-c21grey; // #414042;
            }
            .SideNavigation {
               a {
                  color: @color-c21-gold-ada;
                  font-size: 14px;
                  line-height: 30px;
               }
            }
         }
         #BodyCenterLane {
            .LaneTitle.TitleColspan {
               width: auto;
            }
         }
      }

   }
   .calculator3Lane #BodyCenterLane {
      .LaneTitle.TitleColspan {
         font-family: @subhead-font;

         h1 {
            font-size: 18px;
            font-weight: 700;
            line-height: 38px;

            span {
               color: #beaf87;
            }
         }

         @media (min-width: @screen-tablet-min) {
            width: 568px !important;
            max-width: 568px !important;
         }
         @media(min-width: 1023px) {
           max-width: 784px !important;  width: 784px !important;
         }
      }
   }
   .calculator3Lane #BodyLeftLane {
      padding: 0;
   }
   .calculator3Lane #BodyRightLane {
      @media(max-width: 1023px) {
         width: 285px;
      }
      #calculatorResults {
         @media(max-width: 1023px) {
            width: 257px;
            padding: 0px 15px;
         }
         #calculator_chart {
            @media(max-width: 1023px) {
               display: none;
            }
         }
         //Calculator RentvsBuy
         .rentvsbuylegendcontainer {
            @media(max-width: @screen-tablet-max) {
               display: none;
            }
         }
      }

   }
}

.Standard3Lane #BodyLeftLane {
   position: relative;
   width: 180px;
   padding: 0 0 0 2px;
   margin: 0;
   min-height: 400px;
   float: left;
   background-color: #fff;
   #refineSearch {
      border-top: 1px solid #bbb;
   }
   a#mlsOrAddressUpdateButton:hover, a#mlsOrAddressUpdateCancel:hover,
   a#mlsOrAddressUpdateButton:focus, a#mlsOrAddressUpdateCancel:focus {
      text-decoration: underline;
   }
}
.Standard3Lane #BodyCenterLane {
   position: relative;
   width: 600px;
   padding: 0;
   margin: 0;
   float: left;
   min-height: 370px;
   /*padding-bottom: 30px;      Leave room for the possible bottom pagination */
}
.Standard3Lane #BodyRightLane {
   position: relative;
   width: 200px;
   padding: 0 2px 0 0;
   margin: 0;
   min-height: 400px;
   float: left;
}
.Standard3Lane #BodyOversizedLaneContainer {
   position: relative;
   width: 782px;
   margin: 0;
   min-height: 200px;
   float: left;
}
.Standard3Lane #BodyOversizedLeftLane {
    width: 780px;
    margin-left: 2px;
    background: #fff;
}
.Standard3Lane #BodyRightLane .LaneTitle {
   font-weight: bold;
   font-size: 12px;
   padding-left: 15px;
   background: @color-c21grey; // url(/images/layout/texturesheet.png) -668px -353px no-repeat;
}
.Standard3Lane #BodyLeftLane .LaneTitle {
   font-weight: bold;
   font-size: 14px;
   color: @color-c21grey; // #272727;
   padding-left: 19px;
   background: @color-c21light; // url(/images/layout/texturesheet.png) -668px -479px no-repeat;
}
.Standard3Lane #BodyLeftLane .LaneText {
   padding: 0px 10px 10px 18px;
   color: @dark-grey;  /* Darkened slightly from comp to help legibility and compliance */
}
.Standard3Lane #BodyLeftLane .LaneText h4 {
   font-size: 12px;
   font-weight: bold;
   color: #363636;
   margin-top: 10px;
   letter-spacing: normal;
}

.Standard3Lane #BodyLeftLane .LaneText h5,
.Standard3Lane #BodyLeftLane .LaneText p {
   font-size: 12px;
   font-weight: bold;
   color: #636363;
   margin-top: 10px;
}
.Standard3Lane #BodyLeftLane .LaneText p {
   margin-bottom: 0.5rem;
}
.Standard3Lane #BodyCenterLane .LaneSubTitle {
   color: #fff;
   font-size: 11px;
   font-weight: bold;
   height: 20px;
   line-height: 20px;
   padding-left: 20px;
   background: #000 url(/images/layout/texturesheet.png) -2px -306px no-repeat;
}
.Standard3Lane #BodyCenterLane .LaneSubTitle b {
   color: @color-c21-gold; // #ffc221;
}
.Standard3Lane #BodyCenterLane .LaneTitle.TitleColspan {
   position: relative;
   margin-left: -180px;
   padding-left: 19px;
}
.Standard2LaneRight #BodyRightLane .LaneSubTitle, .Standard3Lane #BodyRightLane .LaneSubTitle {
   padding: 0px 15px;
   background-color: #ccc;
}
.Standard2LaneRight #BodyRightLane a.LaneSubTitle, .Standard3Lane #BodyRightLane a.LaneSubTitle {
   display: block;

}







.Standard2LaneRight #BodyRightLane .LaneSubTitle .secondaryTitle, .Standard3Lane #BodyRightLane .LaneSubTitle .secondaryTitle {
   float: right;
}
.Standard2LaneRight #BodyRightLane .LaneSubTitle .rightLaneAllLabel, .Standard3Lane #BodyRightLane .LaneSubTitle .rightLaneAllLabel {
   display: inline-block;
   width: 170px;
   span {
      span {
         &:hover, &focus {
            text-decoration: underline;
         }
      }
   }
}

.Standard3Lane .TopPagination {
   padding-left: 10px;
}
.Standard3Lane .BottomPagination {
   margin-left: 180px;
   width: 600px;
}

body.pdp-404:before {
   .contentPageHeroImage('/images/backgrounds/content-pages/not_found');
}

body.buying-advice:before {
   .contentPageHeroImage('/images/backgrounds/content-pages/buy_home');
}

body.selling-advice:before {
   .contentPageHeroImage('/images/backgrounds/content-pages/sell_home');
}

body.other:before {
   .contentPageHeroImage('/images/backgrounds/content-pages/other');
}

body.franchise:before, body.career:before {
   .contentPageHeroImage('/images/backgrounds/content-pages/joinc21');
}

/**
 * Standard 1 Column Layout
 */
body.content-page, body.error {
   #PageBody.Standard1Lane {
      @media(max-width: @screen-phone-max) {
         padding-left: 20px;
      }
      .contentArea {
         width: 988px;
         margin: 0 auto;
         @media(max-width: @screen-tablet-max) {
            width: 100%;
            margin: 0;
            padding: 0;
         }
         @media(max-width: @screen-phone-max) {
            width: 100%;
            margin: 0 15px 0 -8px;
            box-sizing: border-box;
            .centerLaneText {
               margin-left: 0;
            }
         }
      }
      .selected {
         > a {
            border-bottom: 1px solid @gold;
            display: block;
         }
         > .contentNavSubheader {
            margin-left: 0;
            border-bottom: 1px solid @color-c21-gold-ada-dark;
            display: block;
         }
         > .contentNavItem {
            border-bottom: 1px solid @gold;
            display: block;
         }
         .selected {
            > a, > .contentNavSubheader, > .contentNavItem {
               border-bottom: none;
            }
         }
      }
      .contentBreadcrumbs {
         position: relative;
         color: @black;
         font-weight: 600;
         text-decoration: none;
         font-size: 14px;
         padding-left: 10px;
         font-family: @subhead-font;

         @media (max-width: @screen-tablet-max) {
            top: 10px;
         }

         @media (max-width: @screen-phone-max) {
            max-width: 250px;
         }

         a {
            text-decoration: none;
            font-size: 14px;
            color: @black;

            &:hover,
            &:focus {
               text-decoration: underline;
            }
         }
         span {
            color: @color-c21-gold-ada;
            font-weight: normal;
            font-size: 14px;
         }
      }
      .contentNavigation {
         display: inline-block;
         float: none;
         vertical-align: top;
         margin-top: -7px;
         padding-left: 10px;
         margin-bottom: 4em;
         @media(max-width: @screen-sm) {
            margin-top: -20px;
         }
         @media(max-width: @screen-phone-max) {
            margin-top: -45px;
         }
         @media (max-width: 480px) {
            width: auto;
            margin-top: -115px;
         }

         .contentNavSection {
            border-bottom: none;
            margin-bottom: 20px;
            a {
               .contentNavHeader {
                  background: transparent;
                  color: @dark-grey;
                  font-weight: 200;
                  text-transform: uppercase;
                  font-family: @subhead-font;
               }
            }
            .contentNavSubsection {
               @media (max-width: @screen-phone-max) {
                  max-width: 250px;
               }
               a:first-child {
                  margin-left: 0;
                  .contentNavSubheader, .contentNavItem {
                     border-top: none;
                     font-family: @body-font;
                     color: @color-c21-gold-ada;
                     background: none;
                     margin-left: 10px;
                     @media (max-width: @screen-phone-max) {
                        max-width: 250px;
                     }
                  }
               }
               a {
                  margin-left: 24px;

                  &:hover,
                  &:focus {
                     text-decoration: underline;
                  }
               }
               .contentNavItem {
                  border-top: none;
               }
               .contentNavSubitem {
                  padding: 5px 0 5px 18px;
                  color: @color-c21-gold-ada;

                  &:hover,
                  &:focus {
                     text-decoration: underline;
                  }

                  &.selected {
                     border-bottom: 1px solid @obsessed-grey;
                     display: block;
                     background: none;
                     color: @obsessed-grey;
                  }
               }
            }
         }
      }
      .contentBody {
         border-left: 1px solid @light-grey;
         padding-left: 20px;
         float: none;
         display: inline-block;
         vertical-align: top;
         margin-right: 0;
         p {
            padding-bottom: 20px;
            line-height: 1.7;
         }
         .contentHeader {
            font-family: @headline-font;
            text-transform: uppercase;
            font-weight: 200;
            margin-bottom: 30px;
         }
         h1.contentHeader {
            color: @color-c21-gold-ada;
            font-family: @headline-font;
            font-size: 28px;
            line-height: 1.2em;
         }
         h1.newcontentHeader {
            letter-spacing: 3.6px;
         }
         .contentPreface {
            font-size: 15px;
            line-height: 1.7;
            font-family: @body-font;
         }
         .contentBodySection {
            line-height: 1.7;
            font-family: @body-font;
            font-size: 15px;
            a:hover,
            a:focus {
               color: @color-c21-gold-ada;
            }
            h2, h3 {
               color: @color-c21-gold-ada;
               font-family: @subhead-font;
               a {
                  font-family: @subhead-font;
               }
            }
            em {
               color: @obsessed-grey;
               font-size: 11px;
               span {
                  color: @obsessed-grey !important;
               }
            }
            p {
               b:hover,
               b:focus {
                  text-decoration: underline;
               }
            }
         }
         @media (min-width: @screen-desktop-min) {
            &.threeColumn {
               .contentBodySection {
                  padding-right: 14px;
               }
            }
         }
         @media (max-width: @screen-phone-max) {
            border-left: none;
            width: 100%;
            display: block;
            padding-left: 0;
         }
      }
      .contentRightLane {
         float: none;
         vertical-align: top;
         width: 195px;
         display: inline-block;
         padding-left: 10px;
         margin-left: 0;
         @media (max-width: @screen-tablet-max) {
            display: none;
         }
         @media (max-width: @screen-phone-max) {
            margin: 0 auto;
            padding-top: 20px;
            width: 75%;
            display: block;
         }
         .StandardBtnCap {
            display: none;
         }
         h3.contentRightLaneBlockHeader {
            color: @gold;
         }
         h1.contentHeader {
            color: @gold;
         }
         #contentContactFranchiseForm {
            .contactFranchiseHeader {
               color: @gold;
            }
         }
      }

   }
}

.Standard1Lane #BodyLeftLane,
.Standard1Lane #BodyCenterLane {
   position: relative;
   padding: 0 2px 0 2px;
   margin: 0;
   min-height: 400px;
   float: left;
}
/**
 * Standard 2 Column Right Layout
 */
.Standard2LaneRight {
   background: url( /images/layout/pagebodybg_standard2laneright.png ) 0 0 repeat-y;
}
.Standard2LaneRight #BodyLeftLane {
   position: relative;
   width: 780px;
   padding: 0 0 0 2px;
   margin: 0;
   min-height: 400px;
   float: left;
   background-color: #fff;
}
.Standard2LaneRight #BodyRightLane {
   position: relative;
   width: 200px;
   padding: 0 2px 0 0;
   margin: 0;
   min-height: 400px;
   float: left;
}
.Standard2LaneRight #BodyLeftLane .LaneTitle {
   padding-left: 19px;
   padding-right: 19px;
}
.Standard2LaneRight #BodyRightLane .LaneTitle {
   font-weight: bold;
   font-size: 12px;
   padding-left: 18px;
   background: url( /images/layout/texturesheet.png ) -668px -353px no-repeat;
}
.Standard2LaneRight #BodyLeftLane .LaneSubTitle {
   font-size: 18px;
   height :34px;
   color: #fff;
   background-color: #212121;
   line-height: 34px;
   margin: 10px;
   padding: 0px 10px;
}
.Standard2LaneRight #BodyLeftLane .LaneSubTitle b {
   color: @color-c21-gold; // #ffc221;
}
.Standard2LaneRight .BottomPagination {
   left: 2px;
   width: 780px;
}

/**
 * Standard 2 Column Left Layout
 */
.Standard2LaneLeft {
   background: url( /images/layout/pagebodybg_standard2laneleft.png ) 0 0 repeat-y;
}
.Standard2LaneLeft #BodyLeftLane {
   position: relative;
   width: 200px;
   padding: 0 0 0 2px;
   margin: 0;
   min-height: 400px;
   float: left;
}
.Standard2LaneLeft #BodyRightLane {
   position: relative;
   width: 780px;
   padding: 0 2px 0 0;
   margin: 0;
   min-height: 400px;
   float: left;
}
.Standard2LaneLeft #BodyLeftLane .LaneTitle {
   font-weight: bold;
   font-size: 14px;
   color: #272727;
   padding-left: 19px;
   background: url(/images/layout/texturesheet.png) -668px -437px no-repeat;
   line-height: 38px;
}
.Standard2LaneLeft .BottomPagination {
   left: 202px;
   width: 780px;
}

#PageBody.Standard2LaneLeft {
   #BodyLeftLane {
      .SideNavigationTitle {
         background: transparent;
         color: #575757;
         font-weight: 200;
         text-transform: uppercase;
         height: 41px;
         font-size: 18px;
         line-height: 41px;
         padding-left: 16px;
         padding-top: 12px;
         font-family: @subhead-font;
      }
      .SideNavigation {
         ul {
            li {
               a {
                  border-top: 0;
                  color: @color-c21-gold; // #2366b2;
                  background: 0;
                  margin-left: 10px;
                  margin-right: 3px;
                  display: block;
                  font-size: 16px;
                  padding-left: 4px;
                  cursor: pointer;
                  padding-top: 16px;
               }
               &.current {
                  border-bottom: 1px solid @redesign-gold;
               }
            }
         }
      }
   }

   #BodyRightLane {
      padding: 0;
      margin-top: 15px;
      border-left: 1px solid #c7c7c7;
      .LaneTitle {
         color: @color-c21-gold; // #f1b32a;
         margin-bottom: -5px;
         text-transform: uppercase;
         font-weight: 200;
         background: transparent;
      }
   }
   .contentArea {
      width: 988px;
      margin: 0 auto;
      @media(max-width: @screen-phone-max) {
         width: 100%;
      }
      .selected {
         > a {
            border-bottom: 1px solid @gold;
            display: block;
         }
         > .contentNavSubheader {
            margin-left: 0;
            border-bottom: 1px solid @gold;
            display: block;
         }
         > .contentNavItem {
            border-bottom: 1px solid @gold;
            display: block;
         }
         .selected {
            > a, > .contentNavSubheader, > .contentNavItem {
               border-bottom: none;
            }
         }
      }
      .contentBreadcrumbs {
         position: relative;
         bottom: 45px;
         color: @white;
         font-weight: 600;
         text-decoration: none;
         font-size: 16px;
         font-family: @subhead-font;
         a {
            color: @white;
            font-weight: 600;
            text-decoration: none;
            font-size: 16px;
         }
         span {
            color: @gold;
            font-size: 16px;
         }
      }
      .contentNavigation {
         display: inline-block;
         float: none;
         vertical-align: top;
         margin-top: -7px;
         @media (max-width: @screen-phone-max) {
            text-align: center;
            margin: 0 auto;
         }
         .contentNavSection {
            border-bottom: none;
            margin-bottom: 20px;
            a {
               .contentNavHeader {
                  background: transparent;
                  font-family: @subhead-font;
                  color: @dark-grey;
                  font-weight: 200;
                  text-transform: uppercase;
               }
            }
            .contentNavSubsection {
               @media (max-width: @screen-phone-max) {
                  max-width: 250px;
                  margin: 0 auto;
               }
               .contentNavSubheader {
                  font-family: @body-font;
               }
               a:first-child {
                  margin-left: 0;
                  .contentNavSubheader {
                     border-top: none;
                     color: @blue;
                     background: none;
                     margin-left: 10px;
                     @media (max-width: @screen-phone-max) {
                        max-width: 250px;
                     }
                  }
               }
               a {
                  margin-left: 24px;
               }
               .contentNavItem {
                  border-top: none;
               }
               .contentNavSubitem {
                  padding: 5px 0 5px 18px;
                  color: @obsessed-grey;

                  &.selected {
                     border-bottom: 1px solid @color-c21-gold-ada;
                     display: block;
                     background: none;
                     color: #858585;
                  }
               }
            }
         }
      }
      .contentBody {
         border-left: 1px solid @light-grey;
         padding-left: 20px;
         float: none;
         display: inline-block;
         vertical-align: top;
         .contentHeader {
            text-transform: uppercase;
            font-weight: 200;
         }
         @media (max-width: @screen-phone-max) {
            border-left: none;
            width: 75%;
            display: block;
            margin: 0 auto;
            padding-left: 0;
         }
      }
      .contentRightLane {
         float: none;
         vertical-align: top;
         display: inline-block;
         @media (max-width: @screen-phone-max) {
            margin: 0 auto;
            padding-top: 20px;
            width: 75%;
            display: block;
         }
      }
   }
}
/* ********************************************************************************************************* */
/* ******************************* START SHARED BUTTON CLASS DEFINITIONS *********************************** */
/* ********************************************************************************************************* */

/*.StandardBtn {
   background:url(/images/layout/texturesheet.png) -1px -328px no-repeat;
   height: 23px;
   position: relative;
   padding: 0px 5px 0px 10px;
   font-size: 12px;
   color:#fff;
   font-family: @subhead-font;
   font-weight:normal;
   display:inline-block;
   line-height:22px;
}
.StandardBtnCap {
   background:url(/images/layout/texturesheet.png) -976px -328px no-repeat;
   width: 7px;
   height: 23px;
   position: absolute;
   right: -7px;
   top: 0px;
   display: inline-block;
}*/
.Standard2LaneRight #BodyRightLane .StandardBtn.FullWidth,
.Standard2LaneLeft  #BodyLeftLane .StandardBtn.FullWidth,
.Standard3Lane #BodyRightLane .StandardBtn.FullWidth,
.Standard3Lane #BodyLeftLane .StandardBtn.FullWidth {
   width:155px;
   margin-bottom:3px;
   text-align:center;
}

body.res {
   #modalContainer {
      .btn-blue {
         background: @color-c21-gold;
         padding: 0px 10px 0px 10px;
         &:hover {
            color: @white;
            background: lighten(@redesign-gold, 10%);
         }
      }
   }
   .StandardBtn.btn.btn-blue {
      font-size: 12px;
      //padding: 0 10px;
   }
   #PageFrame {
      #PageBreadcrumbs {
         margin-top: 60px;
      }
   }
   .js-flyout-open {
      z-index: 5 !important;
   }
   .btn-gold {
      background: @redesign-gold;
      color: @obsessed-grey !important;
      &:hover, &:focus{
         background: lighten(@redesign-gold, 10%);
      }
      &.pagination{
         background: @grey;
         &:hover, &:focus{
            background: lighten(@grey, 10%);
         }
      }
   }
   .BodyRightLane {
      .btn-gold:not(.pagination) {
         background: transparent;
         color: @color-c21grey;
         border: 1px solid @color-c21-gold;
         &:hover, &:focus{
            background: transparent;
         }
      }
   }
   .btn-orange {
      color: @color-c21grey;
      background: transparent; // @orange;
      border: 1px solid @color-c21-gold; // orange;
      &:hover, &:focus{
         border: 1px solid @color-c21darkgold; // orange;
      }
      &.pagination{
         background: @grey;
         &:hover, &:focus{
            background: lighten(@grey, 10%);
         }
      }
   }
   .btn-blue {
      background: @button-blue;
      color: @color-c21grey;
      border: 1px solid @color-c21-gold;
      .border-radius(0px);
      &:hover, &:focus{
         // background: lighten(@button-blue, 10%);
      }
      &.pagination{
         background: @grey;
         &:hover, &:focus{
            // background: lighten(@grey, 10%);
         }
      }
   }
}

/* ********************************************************************************************************* */
/* ******************************* START SHARED LAYOUT CLASS DEFINITIONS *********************************** */
/* ********************************************************************************************************* */
#BodyLeftLane a.LaneTitle, #BodyCenterLane a.LaneTitle, #BodyRightLane a.LaneTitle {
   display: block;
   line-height: 38px;
}

.placeholder {
   color: #ccc !important;
}


/* ********************************************************************************************************* */
/* ******************************************** FOOTER LAYOUT ********************************************** */
/* ********************************************************************************************************* */
#FooterNavigation {
   position: relative;
}

#FooterSeo {
   background-image: url(/images/layout/footer-top.png);
   background-position: top;
   background-repeat: no-repeat;
   padding: 20px 38px 15px 38px;

   zoom: 1;
}

#FooterSeo:after {
   content: ".";
   display: block;
   height: 0;
   clear: both;
   visibility: hidden;
}

#FooterSeoOutside {
   background-image: url(/images/layout/footer-body.png);
   background-position: top;
   background-repeat: repeat-y;
   background-color: #f3f3f3;
   margin-left: 2px;
   margin-right: 2px;
}

#FooterSeo h2 {
   text-transform: uppercase;
   font-size: 100%;
   font: inherit;
   font-weight: bold;
   color: #898989;
   display: block;
   margin: 0;
   padding: 0 0 5px 0;
   border: 0;
   vertical-align: baseline;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}

#FooterSeo .description {
   color: #898989;
   line-height: 12px;
   font-size: 10px;
}

#FooterSeo .description > div {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}

#FooterSeo .description a:hover {
   color: @color-c21-gold; // #0B5095;
}

#FooterSeo .description a {
   color: #898989;
}

#FooterSeo .description.rightSection a {
   color: #898989;
   text-decoration: underline;
}

#FooterSeo .description.rightSection a:hover {
   color: @color-c21-gold; // #0B5095;
}


#PageFooter .FooterTitle {
   text-transform: uppercase;
   padding-bottom: 20px;
   font-weight: bold;
}

#FooterSeo .topSectionCity,
#FooterSeo .topSectionNearbyCity,
#FooterSeo .topSectionZip,
#FooterSeo .topSectionNearbyCounty {
   float: left;
   overflow: hidden;
}

#FooterSeo .topSectionNearbyCity {
   padding-right: 10px;
   width: 195px;
}

#FooterSeo .topSectionZip {
   width: 180px;
   padding-right: 10px;
}

#FooterSeo .topSectionNearbyCounty,
#FooterSeo .topSectionCity {
   width: 230px;
   padding-right: 5px;
}

#FooterSeo .expandoContainer {
   padding-top: 4px;
   display: none;
}

#FooterSeo .bottomSection {
   float: left;
   width: 280px;
   padding-top: 30px;
   padding-right: 20px;
}

#FooterSeo .bottomSectionContainer {
   width: 600px;
   float: left;
   clear: left;
}

#FooterSeo .rightSection.description {
   float: right;
   line-height: 14px;
   width: 270px;
}

#FooterSeo .rightSection.description sup {
   position: relative;
   font-size: 7px;
   top: -0.5em;
   line-height: 0;
   vertical-align: baseline;
}

#FooterHaze {
   background-image: url(/images/layout/footer-haze.png);
   height: 2px;
   position: absolute;
   top: -2px;
   left: 0;
   width: 100%;
   line-height: 1px;
}
.hide {
   display:none;
}
#GlobalAnalytics {
   position: absolute;
   bottom: 0;

   /* See: http://stackoverflow.com/questions/18467529/google-remarketing-tag-iframe-height-issue */
   iframe[name='google_conversion_frame'] {
      height: 0 !important;
      width: 0 !important;
      line-height: 0 !important;
      font-size: 0 !important;
      margin-top: -13px;
      float: left;
   }
}

.inlineGlossaryTerm {
   color: @color-c21-gold; // #fda400;
   font-weight: bold;
}

body.ps-active #XtDirectFloatingButton {
   display: none;
}

#XtDirectFloatingButton {
   // CEN-1491 Display below jwplayer
   z-index: 1000000000 !important;
}

@media print {
   #XtDirectFloatingButton {
      display: none;
   }
}

.ajaxTemplate {
   display: none;
}

.contentTestimonial {
   font-size: 1.3em;
   line-height: 1.5;
   margin-bottom: 17px;
   border-top: 2px solid @color-c21-gold; // #f3b401;
   border-bottom: 2px solid @color-c21-gold; // #f3b401;

   h3 {
      margin: 10px 0;
      color: @color-c21-gold; // #f3b401;
      font-size: 28px;
      font-weight: normal;
   }

   .testimonialInside {
      padding: 8px 28px;

      h4 {
         margin-bottom: 25px;

         a {
            color: #000;
         }
      }

      h4, .testimonialRating {
         color: #242527;
         font-size: 17px;
         font-weight: bold;
      }

      img {
         border: 1px solid #aaa;
         float: left;
         max-width: 15%;
      }

      p {
         margin: 0 0 10px;
      }

      .testimonialTop,
      .testimonialBottom {
         // Subtracting a negative number maintains whitespace
         // and fixes issue with yuicompressor and calc
         // See: https://github.com/yui/yuicompressor/issues/59
         margin-left: calc(~"15% - -1em");
      }

      @media screen and (max-width: 480px) {
         .testimonialBottom {
            margin: 0;
            clear: both;
         }
      }

      .testimonialQuote {
         font-style: italic;
      }

      .testimonialQuote,
      .testimonialAttribution {
         color: #242527;
         color: #999;
         font-size: 16px;
      }
   }
}

.testmonialLegal {
   margin-bottom: 30px;
   font-size: 11px;
   line-height: 14px;
   color: #777;
}

.inlineLegal {
   text-align: left;
   color: @dark-grey;
   text-decoration: none;
   font-size: 11px;
   padding: 0 20px 20px 20px;
   line-height: 13px;
}

#XtDirectPopup {
   max-height: 100%;
   font-family: @headline-font !important;
   > div{
      &:first-child{
         background: @color-c21-gold !important;
      }
      &:not(#PCJSF_Support_ContainerDiv){
         b{
            color: @white;
         }
      }
   }
   #PCJSF_IconContainer {
      display: none;
   }
   #PCJSF_Support_HeaderText {
      margin-left: -50px !important;
      color: #000 !important;
   }
   #PCJSF_TextContainer {
      margin-left: 0px !important;
   }
   #PCJSF_Support_ChatInput {
      color: #4e4a4a !important;
   }
}

//Superbowl page fix
.sbContentBlock {
   .sbSurveyContainer {
      a {
         #arrowClone {
            position: inherit;
         }
      }
   }
}
//Legacy Search page
#realEstateListings {
   .stateMapWrapper {
      width: 100%;
      #listingCountiesDiv {
         width: 100%;
      }
      #listingCitiesDiv {
         width: 100%;
      }
   }
}
.BodyCenterLane {
   .statedetailSitemapLane {
      margin-left: 0;
      width: 984px;
      @media(max-width: @screen-tablet-max) {
         width: 100%;
         .returnToLocationSearch {
            display: none;
         }
         .stateImg {
            display: none;
         }
      }
   }
}

#BodyLeftLane {
   #resultMapContainer {
      margin-left: 0;
      .MicrosoftMap .NavBar_Container {
         z-index: 999;
         &.compact .NavBar_MapTypeButtonIcon {
            width: 31px;
         }
      }

   }
   #resultMapContainerBtm {
      width: 100%;
   }
}
#listAPropertyLayoutInner {
   #listAPropertyForm {
      .formAction {
         a {
            font-weight: 700 !important;
            width: auto;
            height: auto;
            padding: .25em .75em;
            border-radius: 0;
            font-size: 10px;
            &:hover, &:focus {
              background:  #d0c5a8;
            }
         }
      }
   }

}
//Media Center only hopefully
body.content-page.other {
   #PageBody.contentArea.Standard1Lane {
      text-align: center;
      @media(max-width: @screen-tablet-max) {
        text-align: inherit;
      }
      .contentBreadcrumbs, .contentNavigation, .contentBody {
         text-align: left;
         @media(max-width: @screen-tablet-max) {
            text-align: inherit;
         }
      }
      .contentBody {
         padding-left: 0;
         width: 750px !important;
         @media(max-width: @screen-tablet-max) {
            width: 540px !important;
         }
      }

   }
}

@media(max-width: @screen-phone-max) {
   .calculator3Lane {
      #BodyCenterLane {
         #calculatorEntry {
            float: none;
         }
      }
   }
   .amortizationlegendcontainer {
      display: none;
   }
}

@media(max-width: @screen-tablet-max) {
   #BodyLeftLane {
      #officeRightLane {
         #areaServed {
            ul {
               li {
                  a {
                     width: 130px;
                  }
               }
            }
         }
      }
   }
}

body.content-page.other {
   #PageBody.contentArea.Standard1Lane {
      .contentBody {
         @media(max-width: @screen-tablet-max) {
            width: 70% !important;
            &.media-center {
               width: 100% !important;
               margin-left: -10px;
            }
         }
         .LaneText {
            padding: 0;
            padding-left: 20px;
            .calloutclips {
               width: 100%;
            }
            .calloutblack {
               margin: 10px 10px 10px 0px;
            }
         }
      }

   }
}

body.res {
   .findAHomeBody {
      #findAHomeLocation {
         .findHomeSearchInputs {
            width: 550px;
         }
      }
   }
   #listTypeForSaleRadioDiv, #listTypeNewListingsDiv, #listTypeOpenHousesRadioDiv {
      display: block;
   }
   @media(max-width: @screen-tablet-max) {
      #findAHomeSearchTabs {
         width: 768px;
         height: 75px;
         margin-left: 0;
         .searchHeader {
            display: none;
         }
         .searchTab {
            width: 252px;
            margin-left: 3px;
            a {
               img {
                  max-width: 100%;
               }
            }
            &.bymap {
               margin-left: 258px;
            }
            &.byschool {
               margin-left: 513px;
            }
         }
      }
      .findAHomeBody {
         width: 768px;
         .locationSearchInformation {
            .info {
               margin: 0;
            }
         }
         #findAHomeLocation {
            .findHomeSearchInputs {
               width: 345px;
            }
         }
         #findAHomePropertyDetails {
            #propertyEssential {
                  width: 717px;
               .findHomePropDetails {
                  width: 700px;
                  .propBaths, .propPrice, .propBedrooms {
                     .findHomePropLabel {
                        display: block;
                     }
                  }
               }
            }
            #propertyAdditional {
               width: 747px;
               margin-left: 0;
               #propertyTypes {
                  width: 195px;
                  height: 330px;
                  padding-right: 10px;
               }
               #propertySize {
                  .propSizeBox {
                      margin: -4px 10px 0 10px;
                  }
               }
               #listingTypes {
                  .listingTypeBox {
                     .listingTypeRow {
                        margin-left: 0;
                        #listTypeForSaleRadioDiv, #listTypeNewListingsDiv, #listTypeOpenHousesRadioDiv {
                           display: block;
                        }
                     }
                  }
               }
            }
            .findHomeBtm {
               width: 731px;
            }
         }
      }
      .fahPropertyFeature {
         #propertyAdditional {
            #listingTypes {
               padding-left: 0;
            }
         }
      }
      #realEstateListings {
         #searchByMapDiv.searchByMapContainer {
            display: none;
         }
         .stateHeader {
            font-size: 16px;
         }
      }
      #findASchoolMainSearch {
         #findASchoolSearch {
            .locationSearch {
               width: 543px;
            }
         }
         .findHomeSearchInputs {
            width: 312px;
            input {
               width: 300px;
            }
         }
         .locationRequiredInfo {
            position: relative;
            margin: 5px 0px 0px 300px;
         }
      }
      #findAHomeBySchoolBody {
         .infoLabel {
            margin-left: 0;
         }
      }

   }
}

//Content Player Video Work
body.content-page {
   .contentBodySection {
      .centerLaneText {
         color: @gold;
         font-family: @subhead-font;
      }
   }
   #franchiseVideo {
      .centerLaneText {
         color: @gold;
         font-family: @subhead-font;
      }
      h1 {
         .contentHeader {
            color: @gold;
            font-family: @subhead-font;
         }
      }
   }
}
@media(max-width: @screen-phone-max) {
   body.content-page {
      #franchiseVideo {
         margin-bottom: 0;
         .commContainer {
            min-height: 166px !important;
            height: inherit !important;
         }
      }
      .mainVideoBg {
         padding-top: 0;
      }
      .mainVideo {
         background-color: transparent;
         border: none;
         #playerContainer {
            background-color: transparent;
            #redundantPlayerContainer_jwplayer_controlbar {
               display: none;
            }
            #redundantPlayerContainer {
               width: 100% !important;
               background-color: #fff !important;
               height: inherit !important;
               min-height: 166px;
               @media(min-width: @screen-xs) {
                  min-height: 288px;
               }
               #redundantPlayerContainer_displayarea {
                  width: 100% !important;
                  @media(max-width: @screen-xs) {
                     height: 166px !important;
                  }
                  #redundantPlayerContainer_jwplayer_display {
                     width: 100% !important;
                  }
               }
            }
            #redundantPlayerContainer_jwplayer_display, #redundantPlayerContainer_displayarea, #redundantPlayerContainer_jwplayer_display_image {
               position: relative !important;
            }

            #redundantPlayerContainer_displayarea {
               margin-bottom: 10px;
            }
            #redundantPlayerContainer_jwplayer_display_image {

            }
            #redundantPlayerContainer_video {
               @media(max-width: @screen-xs) {
                  width: 320px !important;
                  height: 166px !important;
               }

            }
            #redundantPlayerContainer_video_wrapper {
               @media(max-width: @screen-xs) {
                  left: -20px !important;
               }
            }
         }
      }
      .contentBodySection {
         line-height: 1.7;
         .centerLaneText {
            width: auto;
         }
      }
   }
}


#calculator_chart {
   position: absolute;
   left: -99999px;
}
.calculatorentrygroup input {
   height: 23px;
   &.calcmoneyinput {
      padding-top: 3px;
   }
}
#mortcalcWindow .StandardBtn {
   &:hover, &:focus {
      color: @white;
   }
}


#PageBody.Standard1Lane {
   &.glossary {
      #noResults {
         line-height: 1.5;
      }
   }
   @media(max-width: @screen-phone-max) {
      &.stateMapLane, &.searchByMapBody, &.fahSchoolBody, &.fahPropertyFeature  {
         padding-top: 0;
      }
   }
}

/* Media center */
.contentBody.media-center {
   .LaneTitle {
      color: @gold !important;
      font-family: @headline-font;
      font-size: 28px !important;
   }
   .LaneText {
      line-height: 1.7;
      font-family: @body-font;
      margin-bottom: 30px;
   }
   h4.prListTitle {
      color: @gold !important;
      font-family: @subhead-font;
      margin-top: 30px;
   }
   .fullstory {
      font-family: @body-font;
   }
   ul.pressReleaseList {
      .prTitle.col2,
      .teaser.col2 {
         margin-left: 0;
         clear: both;
         line-height: 1.7
      }
      .prTitle.col2 {
         font-family: @subhead-font;
         margin-bottom: 5px;
      }
      .teaser.col2 {
         font-family: @body-font;
         margin-bottom: 25px;
      }
      .prDate.col1 {
         font-family: @subhead-font;
         margin-bottom: 5px;
      }
   }
}
body.modal-open{
   .header-main-bar{
      z-index: 1;
   }
}
/** Content Override **/
.fahMap .ui-helper-reset {
  width: auto !important;
}
div.contentPreface {
   color: #38383a;
}
*[style*="color: #f1b32a;"] {
   color: @color-c21-gold !important;
}
*[style*="color:#ffd700;"] {
   color: @color-c21-gold !important;
}


/**** Overrides of old defautCSS on legacy pages ****/
.PaginationSort .c21searchselect, .PaginationLimit .c21searchselect {
   width: auto !important;
}

.CardThumb .CardFlag {
   width: 169px !important;
}
.CenterLaneCardHeader h4 {
   letter-spacing: normal;
}
.c21searchselectarrow {
   height: 18px !important;
}

#glossaryStandingHeader{
   h1.newcontentHeader {
      letter-spacing: 3.2px;
      @media (min-width: @screen-sm) {
         margin-bottom: 1.5em;
      }
   }
}
.glossarySearch input {
   height: 22px;
}
#glossaryPageDescription {
   line-height: 1.75em;
}
.clear {
   clear:both;
}


/****** Virtual Open Houses ******/
//Shared on QPDP and PDP
@voh-color: #8b0000;
#virtual-open-houses {
   position: relative;
   padding: .5rem 0;

   &.faux-accordion-item {
      float: left;
      width: 100%;
      padding-top: 15px;
   }

   .voh-card {
      box-shadow: 0 0 5px rgba(0,0,0,.3);
      border-radius: 8px;
      padding: 0 15px;
      .virtual-open-house-header {
         color: @white;
         background-color: @obsessed-grey;
         background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMTEuMDQgMTg1LjQ5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMzIxLjUyLDI3MS4xNWMtNTgsMC0xMDUuNTMsNDEuNi0xMDUuNTMsOTIuNzQsMCwyNC42NCwxMS41Nyw0OC4xNywzMS4yNSw2NS41M2wtMjcuNjIsMjcuMjFoMTAxLjljNTgsMCwxMDUuNTItNDEuNiwxMDUuNTItOTIuNzRTMzc5LjQ3LDI3MS4xNSwzMjEuNTIsMjcxLjE1Wm02NS43NCw5Mi43NHYzOS40NWwtMzQuMTctMTkuNzItNy41Mi00LjM1VjM4N2EyMS44MiwyMS44MiwwLDAsMS0yMS44MiwyMS44MkgyNzcuNTlBMjEuODIsMjEuODIsMCwwLDEsMjU1Ljc3LDM4N1YzNDAuODFBMjEuODIsMjEuODIsMCwwLDEsMjc3LjU5LDMxOWg0Ni4xNmEyMS44MiwyMS44MiwwLDAsMSwyMS44MiwyMS44MnY3LjdsNy41Mi00LjM1LDM0LjE3LTE5LjcyWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxNS45OSAtMjcxLjE1KSIvPjwvc3ZnPg==);
         background-size: 35px 35px;
         background-repeat: no-repeat;
         background-position: 15px 8px;
         padding: 15px 15px 15px 60px;
         margin-left: -15px;
         margin-right: -15px;
         font-size: 15px;
         font-family: @headline-font;
         text-transform: uppercase;
         border-top-left-radius: 8px;
         border-top-right-radius: 8px;
         @media screen and (min-width: @screen-tablet-min) {
            font-size: 18px;
            letter-spacing: 2px;
         }
      }
      .virtual-open-house {
         padding: 1em 0;
         &:not(:last-of-type) {
            border-bottom: 1px solid @text-grey;
         }
         &.platform_1 {
            .voh-info .btn .variable-icon {
               width: 52px;
               background-image: url('/images/c21/propsearch/meeting-icons/icon-facebook-live.svg');
            }
         }
         &.platform_2 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-zoom.svg');
            }
         }
         &.platform_3 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-google-meet.svg');
            }
         }
         &.platform_4 {
            .voh-info .btn .variable-icon {
               width: 25px;
               background-image: url('/images/c21/propsearch/meeting-icons/icon-microsoft-teams.svg');
            }
         }
         &.platform_5 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-youtube-live.svg');
            }
         }
         &.platform_6 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-gotomeeting.svg');
            }
         }
         &.platform_7 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-gotowebinar.svg');
            }
         }
         &.platform_8 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-gotomeetme.svg');
            }
         }
         &.platform_9 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-skype.svg');
            }
         }
         &.platform_10 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-webex.svg');
            }
         }
         &.platform_11 {
            .voh-info .btn .variable-icon {
               width: 30px;
               background-image: url('/images/c21/propsearch/meeting-icons/icon-join-me.svg');
            }
         }
         &.platform_12 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-whereby.svg');
            }
         }
         &.platform_13 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-bluejeans.svg');
            }
         }
         &.platform_14 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-zoho-meeting.svg');
            }
         }
         &.platform_15 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-ring-central.svg');
            }
         }
         &.platform_16 {
            .voh-info .btn .variable-icon {
               background-image: url('/images/c21/propsearch/meeting-icons/icon-live-webinar.svg');
            }
         }

      }

      .voh-info {
         display: flex;
         flex-direction: column;
         .btn {
            margin-top: 0;
            display: flex;
            color: @white;
            background-color: @voh-color;
            border-color: @voh-color;
            letter-spacing: 0;
            @media (min-width: @screen-tablet-min) {
               margin-top: 0;
            }
            &:hover, &:focus {
               background-color: darken(@voh-color, 5%);
            }
            .variable-icon {
               width: 20px;
               height: 20px;
               display: inline-block;
               background-image: url('/images/c21/propsearch/video-icon.svg');
               filter: invert(100%);
               background-repeat: no-repeat;
               background-size: contain;
               background-position: center center;
               margin-right: 4px;
            }
         }

         @media screen and (min-width: @screen-tablet-min) {
            flex-direction: row;
            align-items: center;
            .btn {
               margin-left: auto;
               letter-spacing: 0 !important;
            }
         }

         .voh-time {
            padding-left: 6px;
         }

         .voh-phone {
            padding-left: 12px;
         }

         .cal-btn {
            width: 105px;
            height: 27px;
            margin: .5em 0 2em 0;
            //z-index: 3;
            @media (min-width: @screen-tablet-min) {
               margin: 0 0 0 15px;
            }
            .add-to-calendar {
               a {
                  &:first-of-type {
                     margin-top: 3px;
                  }
               }
               @media (min-width: @screen-tablet-min) {
                  position: absolute;
                  a {
                     &:first-of-type {
                        margin-top: 32px;
                     }
                  }
               }

            }

            label.add-to-calendar-checkbox {
               width: 105px;
               color: @black;
               font-weight: bold;
               background-image: url(/images/c21/propsearch/calendar.svg);
               background-repeat: no-repeat;
               background-position: left center;
               background-size: 27px;
               height: 27px;
               display: block;
               padding-left: 30px;
               line-height: 1.2;
               letter-spacing: .04rem;
               font-size: 12px;
               cursor:pointer;
               z-index: 5;
               @media (min-width: @screen-tablet-min) {
                  position: absolute;
               }
            }

         }

      }

      .voh-title {
         font-weight: bold;
      }
      .voh-description {
         padding-top: 1em;
      }

   }
}

.virtual-open-house-upcoming {
   width: 240px;
   margin-top:1em;
   background: @white;
   box-shadow: 0 0 5px rgba(0,0,0,.3);
   display: flex;
   align-items: stretch;
   z-index: 4;
   @media (min-width: @screen-tablet-min) {
      position: absolute;
      right: 0;
      margin-top: 0;
   }
   .voh-date-wrap {
      width: 75px;
      background: @voh-color;
      color: @white;
      text-align: center;
      padding: 5px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      .voh-day {
         font-family: @headline-font;
         font-size: 35px;
         font-weight: bold;
         line-height: .8;
      }
      .voh-month {
         text-transform: uppercase;
         letter-spacing: 2px;
         line-height: 1;
         padding-top: 4px;
         font-size: 13px;
      }

   }
   .voh-message {
      padding: 5px 0 5px 5px;
      line-height: 1.2;
      display: flex;
      align-items: center;
      font-size: 11px;


   }
   .ico-sm {
      width: 30px;
      height: 30px;
      margin: 3px;
      svg {
         fill: @light-grey;
      }

   }
}

.pdp-explore-section {
   .virtual-open-house-upcoming {
      @media (max-width: @screen-phone-max) {
         position: relative;
         float: left;
         margin-top: 5px;
         margin-bottom: 1em;
      }

      position: relative;
      float: right;
      margin-top: 3px;
   }

   .pdp-section-header {
      padding-bottom: 15px;
      @media (min-width: @screen-tablet-min) and (max-width: @screen-tablet-max){
         padding-bottom: 0;
      }
      .primary-section-header {
         margin-bottom: 15px !important;
         @media (min-width: @screen-tablet-min) {
            float: left;
         }

      }

   }
}
#pdp-body-area {
   #virtual-open-houses .voh-card .voh-info .cal-btn {
      /*@media (min-width: @screen-tablet-min) {
         margin-left: 10px;
      }*/
   }
}
